import React, { useState } from 'react'
import Question from '../models/Question'
import ListController from '../controllers/ListController'

export default function QuestionForm({ question, setQuestion }) {
  function handleChangeText(e) {
    setQuestion(question.merge({ text: e.target.value }))
  }

  function handleChangeType(e) {
    setQuestion(question.merge({ type: e.target.value }))
  }

  function handleChangeCorrectAnswer(e) {
    setQuestion(question.merge({ correct_answers: [e.target.value] }))
  }

  function handleChangeCorrectAnswers(option, isChecked) {
    let newCorrectAnswers = [...(question.correct_answers || [])];

    if (isChecked) {
      if (!newCorrectAnswers.includes(option)) {
        newCorrectAnswers.push(option);
      }
    } else {
      newCorrectAnswers = newCorrectAnswers.filter(item => item !== option);
    }

    setQuestion(question.merge({ correct_answers: newCorrectAnswers }));
  }

  function handleChangeRequired(e) {
    setQuestion(question.merge({ required: !question.required }))
  }

  function setOptions(options) {
    setQuestion(question.merge({ options }))
  }

  function inputNameByCode(code) {
    let inputName
    switch (code) {
      case Question.TYPES.TEXT:
        inputName = window.t('survey.short_answer')
        break;
      case Question.TYPES.SINGLE:
        inputName = window.t('survey.options_pick_one')
        break;
      case Question.TYPES.AI_CHECK:
        inputName = window.t('survey.ai_check')
        break;
      default:
        inputName = window.t('survey.options_pick_many')
    }
    return inputName
  }

  const listController = new ListController(question.options, setOptions)

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <label>{window.t('survey.question_text')}:</label>
          <input type="text" className="form-control" value={question.text} onChange={handleChangeText} />
        </div>

        <div className="col-12 mt-2 form-group">
          <label htmlFor="question-type">{window.t('survey.question_type')}:</label>
          <select
            id="question-type"
            value={question.type}
            onChange={handleChangeType}
            className="form-control"
          >
            {Object.values(Question.TYPES).map(type => (
              <option key={type} value={type}>
                {inputNameByCode(type)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mt-2">
          {question.hasOptions && (
            <fieldset>
              <div className="mb-2">{question.type == Question.TYPES.AI_CHECK ? window.t('survey.ai_options') : window.t('survey.options')}:</div>

              {question.options.map((option, i) => (
                <div className="d-flex mb-2" key={i}>
                  <input
                    type="text"
                    placeholder={window.t('survey.enter_option')}
                    name={option}
                    value={option}
                    className="form-control me-1"
                    onChange={e => listController.set(i, e.target.value)}
                  />
                  <div className="d-flex justify-content-end">
                    <button onClick={() => listController.moveUp(i)} className="btn btn-sm border btn-white btn-icon me-2">
                      <i className="fas fa-angle-up" />
                    </button>
                    <button onClick={() => listController.moveDown(i)} className="btn btn-sm border btn-white btn-icon me-2">
                      <i className="fas fa-angle-down" />
                    </button>
                    <button onClick={() => listController.remove(i)} className="btn btn-sm border btn-white btn-icon">
                      <i className="fas fa-trash-alt" />
                    </button>
                  </div>
                </div>
              ))}
              <p>
                <button onClick={() => listController.add("")} className="btn btn-sm border btn-white btn-xs">
                  <i className="fas fa-plus icon" />
                  {window.t('survey.add_option')}
                </button>
              </p>
              {question.type !== Question.TYPES.AI_CHECK && (
                <>

                  {question.type == Question.TYPES.MULTIPLE ? (

                    <div className="correct-answers-checkboxes">
                      <label>Правильные ответы:</label>
                      {question.options.map((option, i) => (
                        <div className="form-check" key={`checkbox-${i}`}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`correct-answer-${i}`}
                            checked={question.correct_answers && question.correct_answers.includes(option)}
                            onChange={(e) => handleChangeCorrectAnswers(option, e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={`correct-answer-${i}`}>
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <label>Правильный ответ:</label>
                      <select
                        id="correct_answer"
                        name="correct_answer"
                        onChange={handleChangeCorrectAnswer}
                        value={question.correct_answers && question.correct_answers[0] || ''}
                        className="form-select">
                        <option value="">Выберите правильный ответ</option>
                        {question.options.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>

                  )}
                </>
              )}
            </fieldset>
          )}
        </div>


      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-check form-group">
            <input className="form-check-input" type="checkbox" checked={question.required} onChange={handleChangeRequired} id="requiredCheck" />
            <label className="form-check-label" htmlFor="requiredCheck">
              {window.t('survey.required')}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
